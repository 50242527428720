<template>
  <v-card class="pa-4 rounded-lg">
    <h4>Galerias</h4>
    <v-alert type="error" v-if="error">
      {{ error }}
    </v-alert>
    <v-row dense class="mx-0" v-else>
      <template v-if="loading">
        <v-col v-for="i in 12" :key="i" cols="12" sm="6" md="4">
          <v-card elevation="0" rounded="lg" outlined>
            <v-img src="" :aspect-ratio="16 / 9" class="rounded-t-lg">
              <template slot:placeholder>
                <v-skeleton-loader
                  type="image"
                  height="100%"
                  class="rounded-b-0"
                />
              </template>
            </v-img>
            <v-card-text class="pb-2">
              <v-skeleton-loader type="heading" width="180px" />
              <div class="d-flex align-center justify-space-between gap-4 mt-2">
                <span class="text-center">
                  <v-skeleton-loader type="text" width="110px" />
                </span>
                <v-skeleton-loader type="text" width="130px" />
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </template>

      <v-col
        v-for="party in mappedParties"
        :key="party.id"
        cols="12"
        sm="6"
        md="4"
      >
        <v-card
          elevation="0"
          rounded="lg"
          @click="goToPartyGallery(party)"
          outlined
        >
          <v-hover close-delay="250" open-delay="100" v-slot="{ hover }">
            <div>
              <v-img
                :src="party.cover"
                :lazy-src="party.cover"
                :aspect-ratio="16 / 9"
                class="rounded-t-lg"
              >
                <v-fade-transition mode="out-in">
                  <v-carousel
                    v-if="hover"
                    @click.stop
                    :continuous="false"
                    hide-delimiters
                    :show-arrows="true"
                    height="auto"
                    class="rounded-t-lg"
                  >
                    <v-carousel-item
                      v-for="(photo, i) in party.Gallery"
                      :key="i"
                      class="rounded-xl"
                    >
                      <div class="relative">
                        <div
                          class="d-flex flex-column justify-center rounded-t-lg align-center"
                          style="
                            background: rgba(0, 0, 0, 0.9);
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                          "
                        >
                          <v-progress-circular
                            color="white"
                            indeterminate
                          ></v-progress-circular>
                        </div>
                        <v-img
                          class="rounded-t-lg"
                          :src="formatUrl(photo.url, `medium`)"
                          :lazy-src="formatUrl(photo.url, `lazy`)"
                          :aspect-ratio="16 / 9"
                          style="cursor: pointer"
                        />
                      </div>
                    </v-carousel-item>
                    <v-carousel-item>
                      <div class="relative">
                        <v-img
                          class="rounded-t-lg"
                          :src="party.cover"
                          :aspect-ratio="16 / 9"
                          style="cursor: pointer"
                        >
                          <div
                            class="d-flex flex-column h-full pa-6 justify-center rounded-t-lg"
                            style="background: rgba(0, 0, 0, 0.6)"
                          >
                            <h4 class="text-center">Ver todas as fotos</h4>
                          </div>
                        </v-img>
                      </div>
                    </v-carousel-item>
                  </v-carousel>
                </v-fade-transition>
              </v-img>
            </div>
          </v-hover>

          <v-card-text class="pb-2">
            <h5 class="mb-0">{{ party.name }}</h5>
            <div class="d-flex align-center justify-space-between">
              <span class="">
                {{ party.date | date("DD [de] MMM [de] YYYY") }}
              </span>
              <p class="mb-0 text-right">{{ party.Organization.name }}</p>
            </div>
          </v-card-text>
          <div
            v-if="party?.Organization?.Platform"
            style="position: absolute; top: 10px; right: 10px"
            class="d-flex flex-column align-left"
          >
            <v-chip :color="party.Organization.Platform.colors.appBar" small>
              <div>
                <v-img
                  v-if="party.Organization.Platform.logos?.light"
                  :src="party.Organization.Platform.logos.light"
                  height="20px"
                  max-width="54px"
                  contain
                />
              </div>
            </v-chip>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import GALLERY from "@/services/app/gallery";

export default {
  metaInfo: {
    title: "Galerias",
  },
  data() {
    return {
      loading: false,
      error: null,
      index: 0,
      photos: [],
      parties: [],
    };
  },
  methods: {
    async getParties() {
      try {
        this.loading = true;
        this.error = null;
        const { parties } = await GALLERY.search();
        this.parties = parties;
      } catch (error) {
        console.log(error);
        this.error = error.message || "Erro ao buscar galerias.";
      } finally {
        this.loading = false;
      }
    },
    formatUrl(url, quality) {
      return url.replace(/raw$/, quality);
    },
    goToPartyGallery(party) {
      if (party.path.href) {
        window.location.href = party.path.href;
        return;
      }

      this.$router.push({
        name: "shop.party.gallery",
        params: {
          orgSlug: party.Organization?.slug,
          partyId: party.slug || party.id,
        },
      });
    },
  },
  computed: {
    mappedParties() {
      return this.parties.map((party) => {
        const path = `/shop/${party?.Organization.slug}/${
          party.slug || party.id
        }/gallery`;
        let pathTo = { to: path };
        if (party?.Organization?.Platform)
          pathTo = {
            href: `//${party.Organization.Platform.domain}${path}`,
          };

        return {
          ...party,
          path: pathTo,
        };
      });
    },
  },
  mounted() {
    this.getParties();
  },
};
</script>

<style></style>
