import http from "@/http-common";

const download = async (id) => {
  return await http
    .put(`/app/gallery/${id}/download`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};
const view = async (id) => {
  return await http
    .put(`/app/gallery/${id}/view`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const highlight = async () => {
  return await http
    .get(`/app/gallery/highlight`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const search = async (query) => {
  return await http
    .get(`/app/gallery?query=${query}`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

export default {
  download,
  view,
  highlight,
  search
};
